function PrivacyPolicy() {
  return (
    <div className="max-w-6xl mx-auto px-4 py-2">
      <h1 className="text-3xl font-bold mb-8">Politique de Confidentialité</h1>

      <section className="space-y-6">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Notre Engagement</h2>
          <p className="mb-4 text-justify">
            Chez Season: Listes & Recettes, nous accordons une importance capitale à la protection de votre vie privée 
            et de vos données personnelles. Cette politique de confidentialité détaille de manière transparente 
            nos pratiques concernant la collecte, l'utilisation, et la protection de vos informations 
            lorsque vous utilisez notre application. Nous nous engageons à respecter pleinement le 
            Règlement Général sur la Protection des Données (RGPD) et toutes les lois applicables en 
            matière de protection des données.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Données Collectées</h2>
          <p className="mb-4 text-justify">Dans le cadre de nos services, nous collectons les données suivantes :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Identifiants de l'appareil :</strong> Incluant l'ID unique de votre appareil, 
              le système d'exploitation, et le modèle de l'appareil
            </li>
            <li>
              <strong>Données d'utilisation :</strong> Informations sur votre interaction avec l'application, 
              comme les fonctionnalités utilisées, le temps passé sur l'application, et les erreurs rencontrées
            </li>
            <li>
              <strong>Données de performance :</strong> Informations techniques sur les performances 
              de l'application sur votre appareil
            </li>
            <li>
              <strong>Préférences utilisateur :</strong> Vos paramètres et préférences dans l'application
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Base Légale du Traitement</h2>
          <p className="mb-4 text-justify">Nous traitons vos données sur les bases légales suivantes :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Consentement :</strong> Pour la collecte de données non essentielles et 
              l'affichage de publicités personnalisées
            </li>
            <li>
              <strong>Exécution du contrat :</strong> Pour fournir les services essentiels de l'application
            </li>
            <li>
              <strong>Intérêt légitime :</strong> Pour améliorer nos services et assurer la sécurité
            </li>
            <li>
              <strong>Obligation légale :</strong> Pour respecter nos obligations réglementaires
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Utilisation Détaillée de Vos Données</h2>
          <p className="mb-4 text-justify">Vos données sont utilisées pour les finalités suivantes :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Amélioration du service :</strong> Analyse des performances, correction des bugs, 
              optimisation de l'interface utilisateur
            </li>
            <li>
              <strong>Personnalisation :</strong> Adaptation de l'expérience utilisateur selon vos préférences
            </li>
            <li>
              <strong>Publicité :</strong> Affichage de publicités pertinentes via AdMob (uniquement avec 
              votre consentement explicite)
            </li>
            <li>
              <strong>Support technique :</strong> Résolution des problèmes et assistance utilisateur
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Protection et Sécurité des Données</h2>
          <p className="mb-4 text-justify">
            Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles robustes pour 
            protéger vos données :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Chiffrement des données en transit et au repos</li>
            <li>Contrôles d'accès stricts aux données</li>
            <li>Surveillance continue de la sécurité</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Durée de Conservation</h2>
          <p className="mb-4 text-justify">
            Nous conservons vos données personnelles uniquement pendant la durée nécessaire aux finalités 
            pour lesquelles elles ont été collectées :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Données de compte : Jusqu'à la suppression de votre compte</li>
            <li>Données d'utilisation : 12 mois maximum</li>
            <li>Données de support : 24 mois après la dernière interaction</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Transferts de Données</h2>
          <p className="mb-4 text-justify">
            Nos partenaires techniques (Firebase et AdMob) peuvent traiter vos données en dehors de l'UE. 
            Ces transferts sont encadrés par des garanties appropriées (clauses contractuelles types de 
            la Commission européenne) pour assurer un niveau de protection adéquat.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Utilisation de Google Search</h2>
          <p className="mb-4 text-justify">
            Notre application intègre le service Google Search pour la fonctionnalité de recherche. 
            Voici ce que vous devez savoir concernant son utilisation :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Données transmises à Google :</strong> Lorsque vous effectuez une recherche, 
              les termes de recherche et certaines informations techniques (comme votre adresse IP et 
              les paramètres de votre navigateur) sont transmis à Google
            </li>
            <li>
              <strong>Finalité :</strong> Ces données sont utilisées pour fournir des résultats de 
              recherche pertinents et améliorer la qualité du service
            </li>
            <li>
              <strong>Politique de Google :</strong> En utilisant cette fonctionnalité, vos données 
              sont également soumises à la politique de confidentialité de Google 
              (disponible sur http://www.google.com/privacy.html)
            </li>
            <li>
              <strong>Contrôle utilisateur :</strong> Vous pouvez désactiver l'historique de recherche 
              dans vos paramètres Google et gérer vos préférences de confidentialité via votre compte Google
            </li>
          </ul>
          <p className="mb-4 text-justify">
            Nous n'avons pas accès direct aux données collectées par Google lors de vos recherches. 
            Pour plus d'informations sur la manière dont Google traite vos données, nous vous invitons 
            à consulter leur politique de confidentialité.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Intégration avec les Services Google</h2>
          <p className="mb-4 text-justify">
            En plus de Google Search, notre application peut utiliser d'autres services Google pour 
            améliorer votre expérience :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Google Analytics :</strong> Pour analyser l'utilisation de l'application et 
              améliorer nos services
            </li>
            <li>
              <strong>Google Cloud Platform :</strong> Pour l'hébergement et le traitement sécurisé 
              des données
            </li>
          </ul>
          <p className="mb-4 text-justify">
            Ces intégrations sont configurées pour respecter les normes de confidentialité les plus 
            strictes et nous limitons au maximum les données partagées avec ces services.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Vos Droits Détaillés</h2>
          <p className="mb-4 text-justify">Conformément au RGPD, vous disposez des droits suivants :</p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Droit d'accès :</strong> Obtenir une copie de vos données personnelles
            </li>
            <li>
              <strong>Droit de rectification :</strong> Corriger des données inexactes
            </li>
            <li>
              <strong>Droit à l'effacement :</strong> Demander la suppression de vos données
            </li>
            <li>
              <strong>Droit à la limitation :</strong> Restreindre le traitement de vos données
            </li>
            <li>
              <strong>Droit à la portabilité :</strong> Recevoir vos données dans un format structuré
            </li>
            <li>
              <strong>Droit d'opposition :</strong> S'opposer au traitement de vos données
            </li>
          </ul>
          <p className="mb-4 text-justify">
            Pour exercer ces droits ou pour toute question, contactez-nous à contact@season-app.com. 
            Nous nous engageons à répondre dans un délai maximum d'un mois.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Modifications de la Politique</h2>
          <p className="mb-4 text-justify">
            Nous nous réservons le droit de modifier cette politique de confidentialité. Les modifications 
            entrent en vigueur dès leur publication. Pour les changements significatifs, nous vous 
            informerons directement via l'application ou par email.
          </p>
          <p className="mb-4 text-justify">
            Dernière mise à jour : {new Date().toLocaleDateString()}
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Contact</h2>
          <p className="mb-4 text-justify">
            Pour toute question concernant cette politique ou vos données personnelles, contactez notre 
            Délégué à la Protection des Données :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Email : contact@season-app.com</li>
            <li>Adresse : 42 rue Pasteur, 35300 Fougères, FRANCE</li>
            <li>Téléphone : (+33) 06 95 17 35 29</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy; 