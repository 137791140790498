import { ImageType } from '../types/ImageTypes';

interface HomeMobileProps {
  mainImage: ImageType;
  setShowModal: (show: boolean) => void;
  splashMobileImg: string;
  googlePlayImg: string;
}

export function HomeMobile({ mainImage, setShowModal, splashMobileImg, googlePlayImg }: HomeMobileProps) {
  return (
    <div className="flex flex-col w-full md:hidden">
      <div className="relative w-full h-screen">
        <img
          src={splashMobileImg}
          alt={mainImage.alt}
          className="w-auto h-full object-cover mx-auto"
        />
        <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-4">
          <h1 className="text-4xl font-bold text-center mb-4">
            <span className="text-season-green block ">Season</span>
            <span className="text-neutral-700">Listes & Recettes</span>
          </h1>
          <p className="text-xl text-neutral-700 text-center mb-8">
            La bonne saison dans votre assiette
          </p>
          <div className="flex justify-center">
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img
                src={googlePlayImg}
                alt="Get it on Google Play"
                className="w-40 hover:opacity-90 transition-opacity"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="mt-8 px-2">
        <h2 className="text-2xl font-bold text-center mb-6">Au gré des saisons</h2>
        <div className="text-base text-gray-600 dark:text-gray-300 leading-relaxed flex flex-col gap-4 text-justify">
          <p>
            Une application intuitive pour gérer vos repas au quotidien.
            Créez ou importez facilement des recettes depuis le web et
            ajoutez en un clic les ingrédients nécessaires à la liste de courses.
          </p>
          <p>
            Grâce à un planificateur pratique, organisez vos repas pour
            toute la semaine en quelques étapes. Exportez ou importez vos
            données pour une gestion simple et sécurisée, idéale pour
            changer d'appareil ou créer des sauvegardes.
          </p>
          <button
            onClick={() => setShowModal(true)}
            className="text-season-green underline hover:opacity-80 transition-opacity text-center"
          >
            Voir un aperçu
          </button>
        </div>
      </div>
    </div>
  );
} 