function LegalNotice() {
  return (
    <div className="max-w-6xl mx-auto px-4 py-2">
      <h1 className="text-3xl font-bold mb-8">Mentions Légales</h1>

      <section className="space-y-6">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Éditeur du site</h2>
          <p className="mb-2 text-justify">
            Le site web season-app.com est la propriété de Kiato-Web (n°
            Siren/siret)
          </p>
          <p className="mb-2 text-justify">
            Siège Social : 42 rue Pasteur 35300 Fougères FRANCE
          </p>
          <p className="mb-2 text-justify">Tel. : (+33) 06 95 17 35 29</p>
          <p className="mb-2 text-justify">Email : contact@season-app.com</p>
          <p className="mb-2 text-justify">
            Responsable de la publication : Thomas Kiatikhema
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Hébergement du site</h2>
          <p className="mb-2 text-justify">Netlify, Inc.</p>
          <p className="mb-2 text-justify">
            Adresse : 512 2nd Street, Fl 2 - San Francisco, CA 94107
          </p>
          <p className="mb-2 text-justify">Email : privacy@netlify.com</p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Modifications</h2>
          <p className="mb-4 text-justify">
            En accédant et en naviguant sur ce site, l'utilisateur est informé
            de ses droits et obligations et accepte pleinement de se conformer
            aux présentes conditions d'utilisations du site. L'éditeur du site
            se réserve la possibilité de modifier ces conditions. Il appartient
            à l'utilisateur de vérifier périodiquement le contenu des documents
            concernés. L'éditeur se réserve la possibilité de supprimer ou de
            modifier en tout ou partie le « site ».
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Responsabilités</h2>
          <p className="mb-4 text-justify">
            L'éditeur du site Kiato-Web met tout en œuvre pour assurer
            l'exactitude et la mise à jour de l'ensemble des informations
            fournies sur son site. Il ne peut garantir que les informations
            contenues sur le site soient complètes, précises, exactes,
            exhaustives et dépourvues de toute erreur. Kiato-Web s'engage, dès
            qu'un utilisateur quelconque transmet toute erreur constatée aux
            coordonnées ci-dessus, à procéder à la correction dans les plus
            brefs délais.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Accessibilité</h2>
          <p className="mb-4 text-justify">
            Le site est par principe accessible aux utilisateurs 24/24h et 7/7j,
            sauf interruption, programmée ou non, pour des besoins de
            maintenance ou en cas de force majeure. En cas d'indisponibilité
            d'accès au site, celui-ci s'engage à faire son maximum afin de
            rétablir l'accès. Le site ne saurait être tenu pour responsable de
            tout dommage, quelle qu'en soit la nature, résultant de son
            indisponibilité.
          </p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">
            Collecte de données et Loi Informatiques et Libertés
          </h2>
          <p className="font-bold mb-4 text-justify">
            Kiato-Web ne partage, ne vend ni ne loue vos informations
            personnelles à des tiers.
          </p>
          <p className="mb-4 text-justify">
            Conformément à la loi n° 78-17 du 6 janvier 1978, l'utilisateur peut
            à tout moment accéder aux informations personnelles le concernant
            détenues par Kiato-Web, demander leur modification ou leur
            suppression. Ainsi, selon les articles 36, 39 et 40 de la loi
            Informatique et Libertés, l'utilisateur peut demander que soient
            rectifiées, complétées, clarifiées, mises à jour ou effacées les
            informations le concernant qui sont inexactes, incomplètes,
            équivoques, périmées ou dont la collecte ou l'utilisation, la
            communication ou la conservation sont interdites.
          </p>
          <p className="mb-4 text-justify">
            Si vous souhaitez exercer ce droit et obtenir communication des
            informations vous concernant, veuillez-vous adresser :
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>par email à contact@season-app.com</li>
          </ul>
          <p className="mb-4 text-justify">
            Vous pouvez également, pour des motifs légitimes, vous opposer au
            traitement des données vous concernant. Nous nous engageons à
            prendre toutes précautions afin de préserver la sécurité de ces
            informations et notamment empêcher qu'elles ne soient déformées,
            endommagées ou communiquées à des tiers.
          </p>
        </div>
      </section>
    </div>
  );
}

export default LegalNotice; 