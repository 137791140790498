import { ImageType } from '../types/ImageTypes';

interface HomeDesktopProps {
  mainImage: ImageType;
  setShowModal: (show: boolean) => void;
  googlePlayImg: string;
}

export function HomeDesktop({ mainImage, setShowModal, googlePlayImg }: HomeDesktopProps) {
  return (
    <div className="hidden md:flex flex-col items-center w-full">
      <h1 className="text-4xl font-bold mt-8 mb-12 px-4 text-center pt-16">
        <span className="text-season-green">Season:</span> Listes & Recettes
      </h1>

      <div className="w-full max-w-7xl mx-auto flex flex-col items-center gap-8 md:gap-16 px-8">
        <div className="flex flex-row justify-between items-start gap-16 w-full">
          <div className="flex-1 relative w-full">
            <img
              src={mainImage.src}
              alt={mainImage.alt}
              className="w-full h-[45vh] object-contain rounded-2xl shadow-lg"
            />
          </div>

          <div className="flex flex-col flex-1 gap-8">
            <h2 className="text-2xl font-bold text-left">Au gré des saisons</h2>
            <div className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed flex flex-col gap-4 text-justify">
              <p>
                Une application intuitive pour gérer vos repas au quotidien.
                Créez ou importez facilement des recettes depuis le web et
                ajoutez en un clic les ingrédients nécessaires à la liste de courses.
              </p>
              <p>
                Grâce à un planificateur pratique, organisez vos repas pour
                toute la semaine en quelques étapes. Exportez ou importez vos
                données pour une gestion simple et sécurisée, idéale pour
                changer d'appareil ou créer des sauvegardes.
              </p>
              <button
                onClick={() => setShowModal(true)}
                className="text-season-green underline hover:opacity-80 transition-opacity text-left"
              >
                Voir un aperçu
              </button>
            </div>
          </div>
        </div>

        <div>
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
            <img
              src={googlePlayImg}
              alt="Get it on Google Play"
              className="w-52 hover:opacity-90 transition-opacity"
            />
          </a>
        </div>
      </div>
    </div>
  );
} 