import { useState, useEffect, useCallback } from 'react';
import { HomeDesktop } from '../components/HomeDesktop';
import { HomeMobile } from '../components/HomeMobile';
import courseImg from '../assets/course.jpg';
import cuisineImg from '../assets/cuisine.jpg';
import mainImg from '../assets/main.jpg';
import recettesImg from '../assets/Recettes.jpg';
import rechercheImg from '../assets/recherche.jpg';
import planificateurImg from '../assets/planificateur.jpg';
import saisonImg from '../assets/saison.jpg';
import googlePlayImg from '../assets/google_play_fr.webp';
import splashMobileImg from '../assets/splashmobile.jpg';
import { ImageType } from '../types/ImageTypes';

function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const mainImage: ImageType = { 
    src: mainImg, 
    alt: 'Main', 
    isLandscape: true 
  };
  
  const otherImages: ImageType[] = [
    { src: recettesImg, alt: "Recettes", isLandscape: false },
    { src: courseImg, alt: "Course", isLandscape: false },
    { src: cuisineImg, alt: "Cuisine", isLandscape: false },
    { src: rechercheImg, alt: "Recherche", isLandscape: false },
    { src: saisonImg, alt: "Saison", isLandscape: false },
    { src: planificateurImg, alt: "Planificateur", isLandscape: false },
  ];

  const nextSlide = useCallback(() => {
    if (!showModal) {
      setCurrentIndex((prevIndex) => 
        prevIndex === otherImages.length - 1 ? 0 : prevIndex + 1
      );
    }
  }, [showModal, otherImages.length]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isAutoPlaying && !showModal) {
      interval = setInterval(nextSlide, 7000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlaying, showModal, nextSlide]);

  const handleMouseEnter = () => setIsAutoPlaying(false);
  const handleMouseLeave = () => setIsAutoPlaying(true);

  return (
    <>
      <div className="flex flex-col items-center w-full overflow-x-hidden">
        <HomeMobile 
          mainImage={mainImage}
          setShowModal={setShowModal}
          splashMobileImg={splashMobileImg}
          googlePlayImg={googlePlayImg}
        />
        <HomeDesktop 
          mainImage={mainImage}
          setShowModal={setShowModal}
          googlePlayImg={googlePlayImg}
        />
      </div>

      {showModal && (
        <div
          className="fixed inset-0 bg-black/70 flex items-center justify-center z-50"
          onClick={() => {
            setShowModal(false);
            setIsAutoPlaying(true);
          }}
        >
          <div className="relative w-[80vh] max-w-[90vw] flex flex-col gap-4">
            <div className="relative h-[80vh] overflow-hidden rounded-2xl">
              {otherImages.map((image, index) => (
                <div
                  key={index}
                  className="absolute w-full h-full transition-all duration-700 ease-in-out"
                  style={{
                    transform: `translateX(${100 * (index - currentIndex)}%)`,
                    opacity: index === currentIndex ? 1 : 0,
                  }}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-full object-contain"
                  />
                </div>
              ))}

              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCurrentIndex((prevIndex) =>
                    prevIndex === 0 ? otherImages.length - 1 : prevIndex - 1
                  );
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/20 text-white p-3 hover:bg-black/40 transition-colors z-10 rounded-full cursor-pointer"
              >
                ❮
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCurrentIndex((prevIndex) =>
                    prevIndex === otherImages.length - 1 ? 0 : prevIndex + 1
                  );
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/20 text-white p-3 hover:bg-black/40 transition-colors z-10 rounded-full cursor-pointer"
              >
                ❯
              </button>
            </div>

            <div className="flex justify-center gap-3">
              {otherImages.map((_, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex(index);
                  }}
                  className={`w-2.5 h-2.5 rounded-full transition-colors ${
                    index === currentIndex
                      ? "bg-white scale-125"
                      : "bg-white/50"
                  }`}
                />
              ))}
            </div>

            <button
              className="absolute -top-12 right-0 text-white text-xl bg-black/50 w-8 h-8 rounded-full flex items-center justify-center hover:bg-black/70 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(false);
                setIsAutoPlaying(true);
              }}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
