import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useThemeStore } from "./stores/useThemeStore";
import { lightColors, darkColors } from "./theme/GlobalStyle";
import Navbar from "./components/Navbar";
import Home from './pages/Home';
import LegalNotice from "./pages/LegalNotice";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GlobalStyle from "./theme/GlobalStyle";
import Footer from "./components/Footer";

// Create a wrapper component for the main content
function MainContent() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  return (
    <main className={`flex-1 ${!isHomePage ? 'pt-16' : ''}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mentions-legales" element={<LegalNotice />} />
        <Route path="/conditions-generales" element={<TermsOfUse />} />
        <Route path="/politique-confidentialite" element={<PrivacyPolicy />} />
      </Routes>
    </main>
  );
}

function App() {
  const { theme = 'dark' } = useThemeStore();

  return (
    <ThemeProvider theme={theme === "light" ? lightColors : darkColors}>
      <GlobalStyle />
      <BrowserRouter>
        <div className="min-h-screen flex flex-col">
          <Navbar />
          <MainContent />
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
