import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useThemeStore } from '../stores/useThemeStore';
import { useState } from 'react';

const Nav = styled.nav<{ isMenuOpen: boolean }>`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, isMenuOpen }) => 
    isMenuOpen 
      ? theme.card 
      : `${theme.card}99`};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  box-shadow: ${({ isMenuOpen }) => 
    isMenuOpen 
      ? '0 2px 4px rgba(0,0,0,0.1)' 
      : 'none'};
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  
  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const ThemeButton = styled.button`
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
`;

const BurgerButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.text};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const MenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  background-color: ${({ theme }) => theme.card};
  padding-top: 4rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(100%)'};
  z-index: 30;

  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopLinks = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

function Navbar() {
  const { theme = 'dark', toggleTheme } = useThemeStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Nav isMenuOpen={isMenuOpen}>
        <BurgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div />
          <div />
          <div />
        </BurgerButton>
        <ThemeButton onClick={toggleTheme} hidden>
          Switch to {theme === 'dark' ? 'Light' : 'Dark'} Mode
        </ThemeButton>
        <DesktopLinks>
          <NavLink to="/" onClick={handleMenuClick}>Accueil</NavLink>
        </DesktopLinks>
      </Nav>

      <MenuContainer isOpen={isMenuOpen}>
        <NavLink to="/" onClick={handleMenuClick}>Accueil</NavLink>
        <NavLink to="/mentions-legales" onClick={handleMenuClick}>Mentions légales</NavLink>
        <NavLink to="/conditions-generales" onClick={handleMenuClick}>Conditions générales</NavLink>
        <NavLink to="/politique-confidentialite" onClick={handleMenuClick}>Politique de confidentialité</NavLink>
      </MenuContainer>

      {isMenuOpen && (
        <div 
          onClick={() => setIsMenuOpen(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.5)',
            zIndex: 20
          }}
        />
      )}
    </>
  );
}

export default Navbar;
