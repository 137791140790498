import { createGlobalStyle } from 'styled-components';

export const lightColors = {
  primary: '#6200ee',
  background: '#ffffff',
  card: '#ffffff',
  text: '#000000',
  border: '#e0e0e0',
  notification: '#f50057',
};

export const darkColors = {
  primary: '#bb86fc',
  background: '#121212',
  card: '#1e1e1e',
  text: '#ffffff',
  border: '#2c2c2c',
  notification: '#cf6679',
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
`;

export default GlobalStyle;
